import {
  FACEBOOK,
  INSTAGRAM,
  LINKEDIN_BUSINESS_PAGE,
  LINKEDIN_PERSONAL_ACCOUNT,
  TWITTER,
  GOOGLE,
  FACEBOOK_GROUP,
  YOUTUBE,
  TIKTOK_PERSONAL_ACCOUNT,
  TIKTOK_BUSINESS_PAGE,
  PLATFORM_LIST,
  VIDEO_PLACEHOLD,
  WHATSAPP,
  PINTEREST,
  ALL,
  PLATFORM_LIST_WITH_ALL,
  GENERIC
} from './constants';
import facebookIcon from '../assets/platformsIcons/facebook_logo.jpg';
import instagramIcon from '../assets/platformsIcons/instagram_logo.jpg';
import twitterIcon from '../assets/platformsIcons/twitter_x_logo.jpg';
import linkedinIcon from '../assets/platformsIcons/linkedin_logo.jpg';
import googleIcon from '../assets/platformsIcons/google_logo.jpg';

import facebookIconPng from '../assets/platformsIcons/facebook.png';
import instagramIconPng from '../assets/platformsIcons/instagram.png';
import twitterIconPng from '../assets/platformsIcons/twitter.png';
import linkedinIconPng from '../assets/platformsIcons/linkedin.png';
import googleIconPng from '../assets/platformsIcons/google.png';
import tikttokIconPng from '../assets/platformsIcons/tiktok.png';
import youtubeIcon from '../assets/platformsIcons/youtube_logo.png';
import tiktokIcon from '../assets/platformsIcons/tiktok_logo.png';
import whatsAppIcon from '../assets/platformsIcons/whatsapp.png';
import genericIconPng from '../assets/platformsIcons/generic_logo.png';
import pinterestIconPng from '../assets/platformsIcons/pinterest_logo.png';
import videoPlaceholder from '../assets/images/placeholders/videos/video-placeholder.svg';

export const SINGLE_IMAGE_POST_ICON = 'fa-duotone fa-image';
export const MULTI_IMAGE_POST_ICON = 'fa-duotone fa-images';
export const VIDEO_POST_ICON = 'fa-duotone fa-video';
export const REEL_POST_ICON = 'fak fa-lomavis-instagram-reels-logo';
export const STORY_POST_ICON = 'fa-duotone fa-circle-play';
export const TEX_POST_ICON = 'fa-duotone fa-grip-lines';

export const PLATFORM_ICONS = {
  [FACEBOOK_GROUP]: 'fa-brands fa-facebook-f',
  [FACEBOOK]: 'fa-brands fa-facebook-f',
  [INSTAGRAM]: 'fa-brands fa-instagram',
  [TWITTER]: 'fa-brands fa-x-twitter',
  [GOOGLE]: 'fa-kit fa-google',
  [LINKEDIN_BUSINESS_PAGE]: 'fa-brands fa-linkedin',
  [LINKEDIN_PERSONAL_ACCOUNT]: 'fa-brands fa-linkedin',
  [YOUTUBE]: 'fa-brands fa-youtube',
  [TIKTOK_PERSONAL_ACCOUNT]: 'fa-brands fa-tiktok',
  [TIKTOK_BUSINESS_PAGE]: 'fa-brands fa-tiktok',
  [WHATSAPP]: 'fa-brands fa-whatsapp',
  [PINTEREST]: 'fa-brands fa-pinterest-p',
  [GENERIC]: 'fa-duotone fa-solid fa-globe',
  [ALL]: 'fa-duotone fa-solid fa-layer-group'
};

export const ALL_PLATFORM_ICONS = {
  ...PLATFORM_ICONS,
  [ALL]: 'fa-duotone fa-solid fa-layer-group'
};

export const PLATFORM_STATIC_ICONS = {
  [FACEBOOK_GROUP]: facebookIcon,
  [FACEBOOK]: facebookIcon,
  [INSTAGRAM]: instagramIcon,
  [TWITTER]: twitterIcon,
  [GOOGLE]: googleIcon,
  [LINKEDIN_BUSINESS_PAGE]: linkedinIcon,
  [LINKEDIN_PERSONAL_ACCOUNT]: linkedinIcon,
  [YOUTUBE]: youtubeIcon,
  [TIKTOK_PERSONAL_ACCOUNT]: tiktokIcon,
  [TIKTOK_BUSINESS_PAGE]: tiktokIcon,
  [WHATSAPP]: whatsAppIcon,
  [GENERIC]: whatsAppIcon,
  [VIDEO_PLACEHOLD]: videoPlaceholder,
  [PINTEREST]: pinterestIconPng
};

export const PLATFORM_STATIC_ICONS_PNG = {
  [FACEBOOK_GROUP]: facebookIconPng,
  [FACEBOOK]: facebookIconPng,
  [INSTAGRAM]: instagramIconPng,
  [TWITTER]: twitterIconPng,
  [GOOGLE]: googleIconPng,
  [LINKEDIN_BUSINESS_PAGE]: linkedinIconPng,
  [LINKEDIN_PERSONAL_ACCOUNT]: linkedinIconPng,
  [TIKTOK_BUSINESS_PAGE]: tikttokIconPng,
  [TIKTOK_PERSONAL_ACCOUNT]: tikttokIconPng,
  [WHATSAPP]: whatsAppIcon,
  [GENERIC]: genericIconPng,
  [YOUTUBE]: youtubeIcon,
  [PINTEREST]: pinterestIconPng
};
export const additonalPlatformIcons = {
  [LINKEDIN_BUSINESS_PAGE]: 'fa-solid fa-house',
  [FACEBOOK]: 'fa-solid fa-house',
  [LINKEDIN_PERSONAL_ACCOUNT]: 'fa-duotone fa-person',
  [FACEBOOK_GROUP]: 'fa-duotone fa-user-group',
  [TIKTOK_BUSINESS_PAGE]: 'fa-duotone fa-house',
  [TIKTOK_PERSONAL_ACCOUNT]: 'fa-duotone fa-person'
};

export const PLATFORM_COLORS = {
  [FACEBOOK_GROUP]: '#3b5998',
  [FACEBOOK]: '#3b5998',
  [INSTAGRAM]: '#e4405f',
  [TWITTER]: '#1da1f2',
  [GOOGLE]: '#4285f4',
  [LINKEDIN_BUSINESS_PAGE]: '#0077b5',
  [LINKEDIN_PERSONAL_ACCOUNT]: '#0077b5',
  [YOUTUBE]: '#ff0000',
  [TIKTOK_PERSONAL_ACCOUNT]: '#000000',
  [TIKTOK_BUSINESS_PAGE]: '#000000',
  [WHATSAPP]: '#25d366',
  [PINTEREST]: '#bd081c',
  [GENERIC]: '#000000'
};

export const PLATFORM_DISPLAY_NAME_KEYS = PLATFORM_LIST.reduce(
  (acc, platform) => {
    acc[platform] = `PLATFORM_DISPLAY_NAME.${platform.toUpperCase()}`;
    return acc;
  },
  {}
); // Provide {} as the initial value

export const PLATFORM_DISPLAY_NAME_KEYS_WITH_ALL =
  PLATFORM_LIST_WITH_ALL.reduce((acc, platform) => {
    acc[platform] = `PLATFORM_DISPLAY_NAME.${platform.toUpperCase()}`;
    return acc;
  }, {});

export const IS_SHAREABLE_ICON = 'fa-duotone fa-share-nodes';
export const IS_DOWNLOADABLE_ICON = 'fa-duotone fa-arrow-down-to-bracket';
export const IS_CREATED_VIA_MULTIPOST_ICON = 'fa-duotone fa-paste';
export const IS_STORY_ICON = 'fa-duotone fa-play-circle';
export const VIDEO_PLACEHOLDER = 'fa-duotone fa-clapperboard-play';
